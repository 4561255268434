<template>
  <er-data-tables
    ref="table"
    :el-table-props="tableProps"
    v-loading="isLoading"
    :tableData="tableData"
    :columns="tableColumns"
    :action-column="false"
    uniqueId="pond-ss-abw-tab"
    type="white-header-table"
    :sortMethodForColumns="sortMethods()"
    size="mini"
    :columnSelector="true"
    :key="$i18n.locale"
  >
    <template v-slot:doc="{ row }">
      {{ ftm__formatEmpty(ftm__digitPrecision(row.data.doc, 0)) }}
    </template>
    <template v-slot:date="{ row }">
      <span style="color: #155dbe">{{ getEventDate(row.data.date) }}</span>
    </template>
    <template v-slot:abw_range="{ row }">
      {{ ftm__formatEmpty(getABWRange(row.data)) }}
    </template>
    <template v-slot:adg="{ row }">{{
      ftm__formatEmpty(ftm__digitPrecision(row.data.day_gain, 2))
    }}</template>
    <template v-slot:samples="{ row }">
      {{ ftm__formatEmpty(ftm__digitPrecision(row.data.samples)) }}
    </template>
  </er-data-tables>
</template>

<script>
import { mapGetters } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
export default {
  inject: ["parentConfig"],
  mixins: [filtersMixin, datesHandlerMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("pondDetails/pondABWTab", {
      getListOfSSAbwRecords: "getListOfSSAbwRecords",
      getLoadingComponents: "getLoadingComponents"
    }),
    tableProps: function () {
      return {
        // maxHeight: this.parentConfig.maxHeightToPanes["2"]
        defaultSort: {
          prop: "doc",
          order: "descending"
        }
      };
    },
    tableData() {
      return this.$lodash.cloneDeep(this.getListOfSSAbwRecords);
    },
    tableColumns: function () {
      return {
        date: {
          prop: "date",
          label: this.$t("Comn_date"),
          minWidth: 80,
          required: true,
          sortable: "custom"
        },
        doc: {
          prop: "doc",
          label: "DOC",
          minWidth: 80,
          required: true,
          sortable: true
        },
        abw: {
          prop: "day_abw",
          label: this.$t("abw_gms"),
          minWidth: 100,
          required: true,
          sortable: true
        },
        abw_range: {
          label: `${this.ftm__capitalize(this.$t("shrimpsnap.abw_range"))} (g)`,
          minWidth: 100,
          required: true
        },
        adg: {
          prop: "day_gain",
          label: this.$t("Comn_adg_g"),
          // width: "100px",
          sortable: true,
          required: true
        },
        samples: {
          prop: "samples",
          label: this.ftm__capitalize(this.$t("shrimpsnap.samples_with_units")),
          required: true,
          sortable: true
        }
      };
    },
    isLoading() {
      return ["FETCH_SS_DETAILS", "FETCH_SS_IMAGE_DETAILS"].some((x) =>
        this.getLoadingComponents.has(x)
      );
    }
  },
  methods: {
    getABWRange(abwRecord) {
      return [
        this.ftm__digitPrecision(abwRecord.min_abw, 0, "floor"),
        this.ftm__digitPrecision(abwRecord.max_abw, 0, "ceil")
      ]
        .reduce((acc, curr) => {
          if (!curr) {
            return acc;
          }
          if (curr === acc[acc.length - 1]) {
            return acc;
          }
          acc.push(curr);
          return acc;
        }, [])
        .join(" - ");
    },
    sortByCapturedDate(a, b) {
      return this.dhm__dateUtilsLib.dateComparator(a, b, true);
    },
    sortMethods() {
      return {
        date: this.sortByCapturedDate
      };
    },
    getEventDate(date) {
      return this.dhm__formatByTZOnUTCWithLocale(
        date,
        this.upm__getFormatDateString
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
