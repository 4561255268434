var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-data-tables',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],key:_vm.$i18n.locale,ref:"table",attrs:{"el-table-props":_vm.tableProps,"tableData":_vm.tableData,"columns":_vm.tableColumns,"action-column":false,"uniqueId":"pond-ss-abw-tab","type":"white-header-table","sortMethodForColumns":_vm.sortMethods(),"size":"mini","columnSelector":true},scopedSlots:_vm._u([{key:"doc",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(row.data.doc, 0)))+"\n  ")]}},{key:"date",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"color":"#155dbe"}},[_vm._v(_vm._s(_vm.getEventDate(row.data.date)))])]}},{key:"abw_range",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(_vm.ftm__formatEmpty(_vm.getABWRange(row.data)))+"\n  ")]}},{key:"adg",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(row.data.day_gain, 2))))]}},{key:"samples",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(row.data.samples)))+"\n  ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }